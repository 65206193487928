import React from 'react';
import './Services.css';





const Services = () => {
  

  return (
      <div className="services-container">
          
            <h3 className='services-heading'>Services</h3>
            
          <div className='services-text-div'>

                  <div className='services-list'>
                      DENT REPAIR - SCRATCH REPAIR - SCRATCHED RIM REPAIR - PAINT CORRECTION
                  </div>
                  <h2>DENT REPAIR</h2>
                  <b>Type:</b> Medium - Large<br />
                  <b>Time:</b> 2 - 3 hours<br />
                  Example of the dent repair process.
                  <div className="services-video-wrapper">
                      <video controls poster={require('../../images/acura-screenshot.png')}>
                      <source className="services-video" src={require('../../videos/acura.mp4')} type="video/mp4" />
                      </video>
                  </div>
                  <hr />
                  <br /><br />
                  <h2>SCRATCH REPAIR</h2>
                  <b>Type:</b> Surface - Deep<br />
                  <b>Time:</b> 1 - 2 hours<br />
                  Example of the scratch repair process.
                  <div className="services-video-wrapper">
                      <video controls poster={require('../../images/mazerati-screenshot.png')}>
                      <source className="services-video" src={require('../../videos/My Movie 5.mp4')} type="video/mp4" />
                      </video>
                  </div>
                  <hr />
                  <br /><br />
                  <h2>COSMETIC RIM REPAIR</h2>
                  <b>Type:</b> Gouges And Scratches<br />
                  <b>Time:</b> 45 minutes - 1 hour<br />
                  Example of the rim repair process.
                  <div className="services-video-wrapper">
                      <video controls poster={require('../../images/bmw-rim-screanshot.jpeg')}>
                      <source className="services-video" src={require('../../videos/bmw-rims.mp4')} type="video/mp4" />
                      </video>
                  </div>
                  <hr />
                  <br />
                
          </div>
      </div>













  )
}

export default Services